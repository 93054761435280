@charset "UTF-8";
/* We're going to keep basic SCSS variables all in one file.
   In theory, we shouldn't define colors anywhere else but here.
   We obviously do anyway, but it's the thought that counts.
 */
/* $section_background: #ddd; */
/* fa-rub icon settings */
/* Sticky footer */
html {
  position: relative;
  min-height: 100%; }

body {
  margin-bottom: 50px; }

/* Sticky partners_banners + footer */
body.with_partners_banners {
  margin-bottom: 176px; }

footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 50px;
  background-color: #f8f8f8;
  font-size: 85%; }

/* Убираем границу вокруг ссылок  */
a, a:hover, a:active, a:focus {
  outline: 0; }

/* NAVBAR SECTION */
nav.navbar-default {
  font-size: 1.1em;
  background-color: #f8f8f8;
  /* show dropdown immediately */ }
  nav.navbar-default .navbar-header {
    height: 55px; }
  nav.navbar-default .navbar-collapse {
    background-color: #f8f8f8; }
  nav.navbar-default .main-nav > li > a {
    color: #000;
    padding: 16px 10px 19px 10px; }
  nav.navbar-default .main-nav > li > a:hover,
  nav.navbar-default .navbar-default .main-nav > li > a:focus {
    text-decoration: underline; }
  nav.navbar-default .main-nav > li > a.faq {
    padding-top: 14px;
    padding-bottom: 10px; }
  nav.navbar-default .navbar-brand.navbar-logo {
    padding: 0; }
  nav.navbar-default .chavo {
    height: 25px; }
  nav.navbar-default .sign_in {
    font-weight: 600; }
  nav.navbar-default a.staff {
    margin-left: -10px; }
  nav.navbar-default .fa:not(.fa-sign-in) {
    color: #6b6b6b;
    font-size: 1.5em; }
  nav.navbar-default .dropdown-menu a:hover {
    background-color: #e7e7e7; }
  nav.navbar-default .dropdown:hover > .dropdown-menu {
    display: block; }

#my_courses_btn {
  background: #5cb85c;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 5px;
  text-decoration: none;
  color: #000; }
  #my_courses_btn .my_courses_label {
    color: #fff;
    font-weight: 600; }
  #my_courses_btn .my_courses_data {
    display: block;
    font-size: 0.85em; }

#my_courses_btn:hover {
  background: #449d44; }

#my_schoolui_btn {
  background: red;
  border: 1px solid #fff;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 5px;
  text-decoration: none;
  color: #000; }
  #my_schoolui_btn .my_courses_label {
    color: #fff;
    font-weight: 600; }
  #my_schoolui_btn .my_courses_data {
    display: block;
    font-size: 0.85em; }

#my_schoolui_btn:hover {
  background: brown; }

#navbar_logout {
  padding: 0;
  margin-top: 16px;
  margin-left: 10px; }

#navbar-login-button {
  /*  background-color: $green;
  border-color: #fff;*/
  margin-left: 10px; }

#navbar-login-button:hover {
  /*   background-color: #449d44; */ }

/* logout tooltip */
#navbar_logout {
  position: relative; }

#navbar_logout:before {
  transform: translateY(-10px); }

#navbar_logout:hover:before {
  transform: translateY(0px); }

#navbar_logout:before {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  transition: transform 200ms ease, opacity 200ms;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  z-index: 99; }

#navbar_logout:before {
  content: attr(data-tooltip);
  background: #000;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 4px;
  white-space: nowrap;
  text-decoration: none;
  /*  text-transform: uppercase;
    letter-spacing: 1px;*/ }

#navbar_logout:hover:before {
  visibility: visible;
  opacity: 0.80;
  transform: translateY(0px); }

#navbar_logout:before {
  top: 100%;
  left: -100%;
  margin-top: 15px; }

/* the end of logout tooltip */
/* PARTNER BANNERS */
hr.partners_banners {
  border-top: 1px solid #f8f8f8; }

#partners_banners_wrapper {
  /* place it at the bottom of the page */
  position: absolute;
  bottom: 50px;
  height: 126px;
  font-size: 85%;
  width: 100%; }

.partners-footer-buttons img {
  padding: 10px;
  height: 85px; }

/* FOOTER SECTION */
.footer_container {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  height: 100%; }

.footer_support {
  max-width: 50%;
  color: #616161; }
  .footer_support a {
    color: #616161;
    text-decoration: none; }

.support {
  font-weight: 600; }

.footer_addition {
  max-width: 50%; }
  .footer_addition a {
    color: #616161; }

.footer_search, .footer_home {
  padding-left: 15px; }

.footer_search img, .footer_home img {
  width: 40px; }

a.footer_home {
  padding-right: 0 !important; }
