div.st-block-controls {
  font-size: 80%; }

div.st-outer {
  font-size: 14px; }

.st-block input[type="number"] {
  font-size: inherit;
  margin: 0;
  padding: 0.3em 0; }

.st-block input[type="number"],
.st-block input[type="number"]:active,
.st-block input[type="number"]:focus {
  outline: none;
  border: none; }

.st-block input[type="number"],
.st-block input[type="number"]:active,
.st-block input[type="number"]:focus {
  color: #42474b;
  border: 0.1em solid #d4d4d4;
  padding: .6em; }

div.content-block.projectnews-block .list-group-item {
  border: none; }

.sirtrevor-row {
  overflow: hidden;
  height: auto;
  display: flex;
  flex-flow: row; }
